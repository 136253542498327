
.sk-spinner[data-v-76297e5e] {
  height: 33px;
  padding-top: 7px;
  text-align: left;
  margin: 0;
}


.sk-spinner[data-v-658be404] {
  height: 33px;
  padding-top: 7px;
  text-align: left;
  margin: 0;
}

